/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/sanctuary-show@1.0.0/index.min.js
 * - /npm/sanctuary-type-identifiers@2.0.1/index.min.js
 * - /npm/sanctuary-type-classes@11.0.0/index.min.js
 * - /npm/sanctuary-either@1.2.0/index.min.js
 * - /npm/sanctuary-maybe@1.2.0/index.min.js
 * - /npm/sanctuary-pair@1.2.0/index.min.js
 * - /npm/sanctuary-def@0.20.0/index.min.js
 * - /npm/sanctuary@2.0.0/index.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
